import request from '@/utils/request'
// 客户列表页面

// 查询初始化
export function pageInitList() {
   return request({
     url: "/api/amz/customer/pageInit",
     method: "get"
   })
}

// 查询分页 
export function listPage(params) {
  return request({
    url: "/api/amz/customer/listPage",
    params,
    method: "get"
  })
}