<template>
  <span class="Mail">
    <el-link :underline="false" @click="sendMsgInit" type="primary">联系买家</el-link>
    <el-dialog
      v-show="writeMailShow"
      :visible.sync="writeMail"
      style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :modal="false"
      :fullscreen="fullscreen"
      width="900px"
      :show-close="false"
      v-dialog
      :before-close="writeMailClose"
    >
      <template slot="title">
        <div class="headerStyle">
          <span
            @click="writeMailShow = false"
            style="display: inline-block; vertical-align: bottom"
            class="iconfont icon-zuixiaohua"
          ></span>
          <span
            @click="fullscreen = !fullscreen"
            class="iconfont icon-quanping"
          ></span>
          <span @click="writeMailClose" class="el-icon-close"></span>
        </div>
      </template>
      <div style="min-height: 600px; display: flex; flex-direction: column">
        <el-form
          class="writeBox"
          style="background: #f7f7f7"
          ref="form"
          label-width="80px"
        >
          <div>
            <div class="send">
              <el-link @click="copyer = !copyer" v-if="!copyer" type="primary"
                >抄送</el-link
              >
              <el-link
                @click="secreter = !secreter"
                v-if="!secreter"
                type="primary"
                >密送</el-link
              >
            </div>
            <el-form-item label="收件人">
              <el-input
                onkeyup="this.value=this.value.replace(/[, ]/g,'')"
                v-model="sendInfo.toArr"
                placeholder="输入多个收件人用英文符号(;)分隔，最多5个"
              ></el-input>
            </el-form-item>
          </div>
          <div v-if="copyer">
            <span
              @click="closeInput('抄送人')"
              class="send el-icon-delete"
            ></span>
            <el-form-item label="抄送人">
              <el-input
                onkeyup="this.value=this.value.replace(/[, ]/g,'')"
                v-model="sendInfo.ccArr"
                placeholder="输入多个抄送人用英文符号(;)分隔，最多5个"
              ></el-input>
            </el-form-item>
          </div>
          <div v-if="secreter">
            <span
              @click="closeInput('密送人')"
              class="send el-icon-delete"
            ></span>
            <el-form-item label="密送人">
              <el-input
                onkeyup="this.value=this.value.replace(/[, ]/g,'')"
                v-model="sendInfo.bccArr"
                placeholder="输入多个密送人用英文符号(;)分隔，最多5个"
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="主题">
              <el-input v-model="sendInfo.subject" placeholder=""></el-input>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label-width="0">
              <el-upload
                style="display: inline-block"
                class="upload-demo"
                :action="actionOverall"
                :headers="mytoken"
                :on-progress="uploadProgress"
                :on-success="uploadSuccess"
                multiple
                :limit="5"
                :show-file-list="false"
                :file-list="fileList"
              >
                <span style="margin-left: 20px; color: black; font-size: 13px"
                  ><i
                    style="font-size: 13px"
                    class="iconfont icon-shangchuan"
                  ></i
                  >上传附件<i
                    style="margin-left: 4px"
                    :class="progressLoading ? 'el-icon-loading' : ''"
                  ></i
                ></span>
              </el-upload>
              <span class="file" v-for="(item, index) in fileList" :key="item">
                <span
                  style="color: orange; margin: 0 5px 0 9px"
                  class="el-icon-paperclip"
                ></span>
                <span style="width: 80px" class="file on-line">{{ item }}</span>
                <i @click="delFile(index)" class="el-icon-close delFile"></i>
              </span>
            </el-form-item>
          </div>
        </el-form>
        <div @mouseenter="autotip" style="flex: 1; margin-bottom: 50px">
          <quill-editor
            v-model="sendInfo.content"
            ref="myQuillEditor"
            :options="editorOption"
          >
          </quill-editor>
          <!-- 从数据库读取展示 -->
          <div v-if="writeMailType != 'newMail'" v-dompurify-html="strip"></div>
          <div
            v-if="writeMailType != 'newMail'"
            v-dompurify-html="str"
            style="
              max-width: 900px;
              margin: 10px;
              max-height: 600px;
              overflow: auto;
            "
          ></div>
        </div>
        <div class="writeFoot">
          <div>
            <span class="tempspan">
              <el-popover
                placement="top-start"
                width="500"
                close-delay="0"
                @after-leave="nameeditHide"
                ref="popover"
                trigger="click"
              >
                <div class="temp" v-loading="loading.tagLoading">
                  <div class="muban">
                    <el-input
                      @keydown.enter.prevent.native="selectTag"
                      v-model="tagKeyword"
                      placeholder="搜索模板名称,回车搜索"
                    ></el-input>
                    <p
                      @mouseenter="enterTemplate(item)"
                      @mouseleave="leaveTemplate"
                      @click="jointContent(item.content)"
                      v-for="item in templateList"
                      :key="item.id"
                    >
                      <span
                        class="on-line"
                        style="width: 120px; cursor: pointer"
                        >{{ item.name }}</span
                      >
                    </p>
                    <el-button
                      @click="$router.push('/messageTemplate')"
                      style="
                        width: 150px;
                        border-top: 1px solid #e4e7ed;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                      "
                      type="text"
                      >添加模板</el-button
                    >
                  </div>
                  <div>
                    <p style="padding-left: 15px">
                      模板名称: {{ templateName ? templateName : "请选择模板" }}
                    </p>
                    <div
                      v-dompurify-html="templateContent"
                      style="
                        white-space: pre-line;
                        margin-top: 10px;
                        font-size: 14px;
                        color: #999999;
                        line-height: 25px;
                        padding-left: 12px;
                      "
                      v-show="templateName"
                    ></div>
                    <div
                      style="
                        text-align: center;
                        margin-top: 100px;
                        font-size: 14px;
                        color: #999999;
                      "
                      v-show="!templateName"
                    >
                      请选择具体模板
                    </div>
                  </div>
                </div>
                <span slot="reference">
                  <span @click="tagListPage">
                    选择模板
                    <i class="el-icon-caret-bottom c"></i>
                  </span>
                </span>
              </el-popover>
            </span>
            <!-- <span style="color: #e6e8ed">|</span> -->
            <!-- <span @click="sendMail('存草稿')">存草稿</span> -->
            <!-- <span style="color:#E6E8ED">|</span>
                        <span>定时发送</span> -->
          </div>
          <div>
            <span style="margin-right: 15px"
              >
              <el-dropdown trigger="click" placement="top">
                <span>发件邮箱</span><i class="el-icon-caret-bottom"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in accountList"
                    :key="item.id"
                    @click.native="getSendAccount(item)"
                    >{{ item.value }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              {{ accountName ? accountName : "该店铺下未绑定邮箱" }}
            </span>
            <el-button
              size="mini"
              @click="sendMail"
              type="primary"
              :loading="loading.buttonLoading"
              >发送</el-button
            >
          </div>
        </div>
      </div>
      <el-dialog
        title="提示"
        :visible.sync="hint"
        width="400px"
        append-to-body
        :before-close="dialogBeforeClose"
      >
        <template slot="title">
          <div style="padding: 20px">提示</div>
        </template>
        <div style="padding: 0 0 15px 20px">
          <span style="color: orange" class="el-icon-warning"></span>
          是否保存当前内容至草稿箱？
        </div>
        <div slot="footer">
          <el-button @click="hint = false">取 消</el-button>
          <el-button v-if="false" type="primary" @click="sendMail('存草稿')"
            >保存</el-button
          >
          <el-button type="primary" @click="writeMailClose">不保存</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </span>
</template>

<script>
import ShopsAndSites from "@/components/ShopsAndSites";
import TimeQuantum from "@/components/TimeQuantum";
import { titleConfig, editorOption } from "@/api/Mail/mailRules.js";
import {
  tagListPage,
  sendMsgInit,
  sendMsg,
} from "@/api/Mail/mailMessage.js";
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import quill from "quill"; // 引入编辑器
import ImageResize from "quill-image-resize-module";
export default {
  props: ["shopId","marketPlaceId"],
  components: {
    quillEditor
  },
  data() {
    var token = window.sessionStorage.getItem("token");
    return {
      mytoken: { token: token },
      writeMailShow: false,
      writeMail: false,
      fullscreen: false,
      copyer: false,
      secreter: false,
      progressLoading: false,
      loading: {
        tagLoading: false
      },
      sendInfo: {
        toArr: "",
        ccArr: "",
        bccArr: "",
        subject: "",
        content: "",
        fileId: [],
        progressLoading: "",
        accountId: ""
      },
      fileList: [],
      titleConfig: titleConfig,
      editorOption: editorOption,
      writeMailType: "",
      str: "",
      tagKeyword: "",
      templateName: "",
      templateContent: "",
      templateList: [],
      accountName: "",
      hint: false
    };
  },
  computed: {
    actionOverall() {
      return process.env.VUE_APP_API_URL + "/api/file/upload/uploadFile";
    }
  },
  methods: {
    sendMsgInit() {
      this.loadingfull = true;
      sendMsgInit({
        shopId: this.shopId,
        marketPlaceId: this.marketPlaceId,
      })
        .then(res => {
          if (res.data.code == 200) {
            this.loadingfull = false;
            this.writeMailShow = true;
            this.writeMail = true;
            this.accountList = res.data.data.accountList;
            this.templateList = res.data.data.templateList;
            if (res.data.data.replyMsg) {
              if (this.writeMailType == "Re" || this.writeMailType == "ReAll") {
                this.sendInfo.toArr = res.data.data.replyMsg.showMailTo;
              }
              this.sendInfo.subject = res.data.data.replyMsg.subject;
            }
            const flag = this.accountList.some(item => {
              if (item.isSelected == 1) {
                this.accountName = item.value;
                this.sendInfo.accountId = item.id;
              }
              return item.isSelected == 1;
            });
            if (!flag) {
              this.sendInfo.accountId = this.accountList[0].id;
              this.accountName = this.accountList[0].value;
            }
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {
          this.loadingfull = false;
        });
    },
    closeInput(info) {
      if (info == "抄送人") {
        this.copyer = !this.copyer;
        this.sendInfo.ccArr = "";
      } else {
        this.secreter = !this.secreter;
        this.sendInfo.bccArr = "";
      }
    },
    getSendAccount(item) {
      this.sendInfo.accountId = item.id;
      this.accountName = item.value;
    },
    sendMail(info) {
      var toArr = [];
      var bccArr = [];
      var ccArr = [];
      this.sendInfo.toArr = this.sendInfo.toArr.replace(/\s+/g, "");
      this.sendInfo.bccArr = this.sendInfo.bccArr.replace(/\s+/g, "");
      this.sendInfo.ccArr = this.sendInfo.ccArr.replace(/\s+/g, "");

      if (!this.sendInfo.toArr) return this.$message.error("请输入收件人");

      if (this.sendInfo.toArr && this.sendInfo.toArr.indexOf(";") >= 0) {
        toArr = this.sendInfo.toArr.split(";").map(item => {
          item = item.includes("<")
            ? item.substring(item.indexOf("<") + 1, item.lastIndexOf(">"))
            : item;
          return item;
        });
      } else {
        toArr = this.sendInfo.toArr
          ? this.sendInfo.toArr.includes("<")
            ? [
                this.sendInfo.toArr.substring(
                  this.sendInfo.toArr.indexOf("<") + 1,
                  this.sendInfo.toArr.lastIndexOf(">")
                )
              ]
            : [this.sendInfo.toArr]
          : [];
      }
      if (toArr[toArr.length - 1] == "") toArr.splice(toArr.length - 1, 1);

      if (this.sendInfo.bccArr && this.sendInfo.bccArr.indexOf(";") >= 0) {
        bccArr = this.sendInfo.bccArr.split(";").map(item => {
          item = item.includes("<")
            ? item.substring(item.indexOf("<") + 1, item.lastIndexOf(">"))
            : item;
          return item;
        });
      } else {
        bccArr = this.sendInfo.bccArr
          ? this.sendInfo.bccArr.includes("<")
            ? [
                this.sendInfo.bccArr.substring(
                  this.sendInfo.bccArr.indexOf("<") + 1,
                  this.sendInfo.bccArr.lastIndexOf(">")
                )
              ]
            : [this.sendInfo.bccArr]
          : [];
      }
      if (bccArr[bccArr.length - 1] == "") bccArr.splice(bccArr.length - 1, 1);

      if (this.sendInfo.ccArr && this.sendInfo.ccArr.indexOf(";") >= 0) {
        ccArr = this.sendInfo.ccArr.split(";").map(item => {
          item = item.includes("<")
            ? item.substring(item.indexOf("<") + 1, item.lastIndexOf(">"))
            : item;
          return item;
        });
      } else {
        ccArr = this.sendInfo.ccArr
          ? this.sendInfo.ccArr.includes("<")
            ? [
                this.sendInfo.ccArr.substring(
                  this.sendInfo.ccArr.indexOf("<") + 1,
                  this.sendInfo.ccArr.lastIndexOf(">")
                )
              ]
            : [this.sendInfo.ccArr]
          : [];
      }
      if (ccArr[ccArr.length - 1] == "") ccArr.splice(ccArr.length - 1, 1);

      const regular = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      const flagtoArr =
        toArr.length == 0
          ? false
          : toArr.some(item => {
              return regular.test(item) == false;
            });
      const flagbccArr =
        bccArr.length == 0
          ? false
          : bccArr.some(item => {
              return regular.test(item) == false;
            });
      const flagccArr =
        ccArr.length == 0
          ? false
          : ccArr.some(item => {
              return regular.test(item) == false;
            });
      if (flagtoArr || flagbccArr || flagccArr) {
        this.$message.error("邮箱格式错误 提示 用 ; 隔开或邮箱本身书写");
        return;
      }

      // if (info == "存草稿") {
      //   this.$set(this.loading, "buttonLoading", true);
      //   this.writeMailShow = false;
      //   this.loadingfull = true;
      //   saveDrafts({
      //     ...this.sendInfo,
      //     content: this.sendInfo.content,
      //     toArr: toArr,
      //     ccArr: ccArr,
      //     bccArr: bccArr,
      //     id: this.chatBoxInfo.id ? this.chatBoxInfo.id : null
      //   })
      //     .then(res => {
      //       this.writeMailClose();
      //       this.loadingfull = false;
      //       this.$set(this.loading, "buttonLoading", false);
      //       if (res.data.code == 200) {
      //         if (this.getMailInfo.accountId) this.mailListPage();
      //         this.$message.success(res.data.message);
      //         if (this.chatBoxInfo.id) {
      //           this.chatBoxInfo = {
      //             accountId: "",
      //             sender: "",
      //             infotype: "",
      //             contentList: [],
      //             date: "",
      //             mailTo: "",
      //             subject: "",
      //             content: "",
      //             fileList: [],
      //             id: "",
      //             shopId: "",
      //             boxType: "Drafts",
      //             cc: ""
      //           };
      //         }
      //       } else {
      //         this.loadingfull = false;
      //         this.$message.error(res.data.message);
      //       }
      //     })
      //     .catch(() => {
      //       this.loadingfull = false;
      //     });
      // } else {
      if (!this.sendInfo.accountId)
        return this.$message.error("该店铺下未绑定账号");
      this.$set(this.loading, "buttonLoading", true);
      sendMsg({
        ...this.sendInfo,
        content:
          this.writeMailType == "Re" || this.writeMailType == "Fw"
            ? this.sendInfo.content + this.strip + this.chatBoxInfo.content
            : this.sendInfo.content,
        toArr: toArr,
        ccArr: ccArr,
        bccArr: bccArr
      })
        .then(res => {
          this.$set(this.loading, "buttonLoading", false);
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.writeMailClose();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {
          this.$set(this.loading, "buttonLoading", false);
        });
      // }
    },
    nameeditClick(scope) {
      this.$refs["popover"].doClose();
      this.tagKeyword = "";
    },
    jointContent(info) {
      const editor = document.getElementsByClassName("ql-editor")[0];
      editor.children[0].innerHTML += info;
      this.nameeditClick();
    },
    leaveTemplate() {
      this.templateName = "";
      this.templateContent = "";
    },
    selectTag() {
      this.tagListPage();
    },
    enterTemplate(info) {
      this.templateName = info.name;
      this.templateContent = info.content;
    },
    tagListPage() {
      console.log(this.tagKeyword);
      this.$set(this.loading, "tagLoading", true);
      tagListPage({
        keyword: this.tagKeyword,
        shopAndMarketPlaceIds: this.shopAndMarketPlaceIds
      })
        .then(res => {
          this.$set(this.loading, "tagLoading", false);
          this.templateList = res.data.values;
        })
        .catch(() => {
          this.loadingfull = false;
          this.$set(this.loading, "tagLoading", false);
        });
    },
    delFile(index) {
      this.sendInfo.fileId.splice(index, 1);
      this.fileList.splice(index, 1);
    },
    autotip() {
      for (let item of this.titleConfig) {
        let tip = document.querySelector(".quill-editor " + item.Choice);
        if (!tip) continue;
        tip.setAttribute("title", item.title);
      }
    },
    uploadSuccess(res, file, fileList) {
      this.progressLoading = false;
      if (res.code == 200) {
        this.$message.success(res.message);
        this.sendInfo.fileId.push(res.data.fileId);
        this.fileList.push(file.name);
      } else {
        this.$message.warning(res.message);
      }
    },
    uploadProgress() {
      this.progressLoading = true;
    },
    uploadSuccessStation(res, file, fileList) {
      this.progressLoading = false;
      this.loadingfull = false;
      if (this.chatBoxInfo.boxType == "RakuTenMail") {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.sendStationInfo.fileId = [res.data.result];
          this.fileListStation = [res.data.result];
        } else {
          this.$message.warning(res.message);
        }
      } else {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.sendInfo.fileId.push(res.data.fileId);
          this.fileListStation.push(file.name);
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    writeMailClose() {
      this.sendInfo = {
        accountId: "",
        bccArr: "",
        ccArr: "",
        content: "", //富文本内容
        fileId: [],
        replyToArr: [],
        subject: "",
        toArr: ""
      };
      this.accountName=''
      this.fileList = [];
      this.str = "";
      this.writeMailType = "";
      this.writeMail = false;
      this.writeMailType = "";
      this.hint = false;
    }
  }
};
</script>

<style scoped lang='scss'>
.headerStyle {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #e4e8ed;
  span {
    margin-right: 4px;
    color: #999999;
  }
  span:hover {
    color: black;
    font-weight: 700;
  }
}
.bodyfull {
  background: white !important;
}
.wrapper {
  position: relative;
}
#input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}
::v-deep .ql-blank {
  border: 0;
  border-top: 1px solid #e4e8ed;
  max-height: 300px;
  overflow: auto;
}
::v-deep .ql-snow {
  border: 0;
  text-align: left;
}
::v-deep .ql-editor p {
  overflow: auto;
  white-space: pre-line;
  // margin-bottom: 90px;
}
.ql-editor {
  min-height: 150px;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .writeBox .el-input__inner {
  border: 0;
  background: transparent;
}
::v-deep .writeBox .el-form-item {
  padding-bottom: 0;
  margin-bottom: 0;
}
::v-deep .writeBox .el-form-item__label {
  color: #888888;
}
.writeFoot {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 9px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    padding: 0 2px;
    cursor: pointer;
  }
}
.writeBox {
  > div {
    border-bottom: 1px solid #e6e8ed;
    position: relative;
    .send {
      position: absolute;
      cursor: pointer;
      z-index: 99;
      right: 16px;
      top: 10px;
      a {
        margin-left: 10px;
      }
      span {
        cursor: pointer;
      }
    }
  }
}
::v-deep .muban {
  .el-input__inner {
    border: 0;
    border-bottom: 1px #e4e7ed solid;
  }
}
.file {
  display: inline-block;
  background: #eeeeee;
  border-radius: 19px;
  height: 30px;
  line-height: 30px;
  padding-right: 10px;
  margin-right: 5px;
  > span {
    display: inline-block;
    vertical-align: middle;
  }
  .delFile {
    display: inline-block;
    width: 12px;
    height: 12px;
    font-size: 11px;
    vertical-align: middle;
    cursor: pointer;
  }
}
.on-line {
  display: inline-block;
  min-width: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // vertical-align: middle;
}
.temp {
  height: 450px;
  width: 100%;
  display: flex;
  position: relative;
  > div {
    display: inline-block;
    height: 100%;
  }
  > div:nth-of-type(1) {
    > p {
      line-height: 25px;
      padding-left: 8px;
      cursor: pointer;
      // width: 130px;
    }
    > p:hover {
      background: #f7f7f7;
    }
  }
  > div:nth-of-type(2) {
    width: 350px;
    // display: none;
    border-left: 1px #e4e7ed solid;
  }
}
</style>