import request from '@/utils/request'
// feedback 页面

//  查询初始化
export function pageInit() {
   return request({
     url: "/api/feedback/pageInit",
     method: "get"
   })
}

// 查询分页
export function getlistPage(params) {
  return request({
    url: "/api/feedback/poor/listPage",
    params,
    method: "get"
  })
}

// 修改状态
export function modifyState(data) {
  return request({
    url: "/api/feedback/dealState",
    data,
    method: "put"
  })
}

// 查询跟进人数据
export function followUserInit() {
  return request({
    url: "/api/feedback/follow/pageInit",
    method: "get"
  })
}

// 跟进记录查询
export function followListPage(params) {
  return request({
    url: "/api/feedback/follow/listPage",
    params,
    method: "get"
  })
}

// 新增内容
export function addContent(data) {
  return request({
    url: "/api/feedback/follow",
    data,
    method: "post"
  })
}

// 操作日志查询
export function LogListPage(params) {
  return request({
    url: "/api/feedback/operate/listPage",
    params,
    method: "get"
  })
}

// 编辑备注
export function editTips(value, userId, id) {
  return request({
    url: `/api/feedback/tips/${id}?content=${value}&operateBy=${userId}`,
    method: "post"
  })
}