export const titleConfig = [{
        Choice: '.ql-bold',
        title: '加粗'
    },
    {
        Choice: '.ql-italic',
        title: '斜体'
    },
    {
        Choice: '.ql-underline',
        title: '下划线'
    },
    {
        Choice: '.ql-header',
        title: '段落格式'
    },
    {
        Choice: '.ql-strike',
        title: '删除线'
    },
    {
        Choice: '.ql-blockquote',
        title: '块引用'
    },
    {
        Choice: '.ql-code',
        title: '插入代码'
    },
    {
        Choice: '.ql-code-block',
        title: '插入代码段'
    },
    {
        Choice: '.ql-font',
        title: '字体'
    },
    {
        Choice: '.ql-size',
        title: '字体大小'
    },
    {
        Choice: '.ql-list[value="ordered"]',
        title: '编号列表'
    },
    {
        Choice: '.ql-list[value="bullet"]',
        title: '项目列表'
    },
    {
        Choice: '.ql-direction',
        title: '文本方向'
    },
    {
        Choice: '.ql-header[value="1"]',
        title: 'h1'
    },
    {
        Choice: '.ql-header[value="2"]',
        title: 'h2'
    },
    {
        Choice: '.ql-align',
        title: '对齐方式'
    },
    {
        Choice: '.ql-color',
        title: '字体颜色'
    },
    {
        Choice: '.ql-background',
        title: '背景颜色'
    },
    {
        Choice: '.ql-image',
        title: '图像'
    },
    {
        Choice: '.ql-video',
        title: '视频'
    },
    {
        Choice: '.ql-link',
        title: '添加链接'
    },
    {
        Choice: '.ql-formula',
        title: '插入公式'
    },
    {
        Choice: '.ql-clean',
        title: '清除字体格式'
    },
    {
        Choice: '.ql-script[value="sub"]',
        title: '下标'
    },
    {
        Choice: '.ql-script[value="super"]',
        title: '上标'
    },
    {
        Choice: '.ql-indent[value="-1"]',
        title: '向左缩进'
    },
    {
        Choice: '.ql-indent[value="+1"]',
        title: '向右缩进'
    },
    {
        Choice: '.ql-header .ql-picker-label',
        title: '标题大小'
    },
    {
        Choice: '.ql-header .ql-picker-item[data-value="1"]',
        title: '标题一'
    },
    {
        Choice: '.ql-header .ql-picker-item[data-value="2"]',
        title: '标题二'
    },
    {
        Choice: '.ql-header .ql-picker-item[data-value="3"]',
        title: '标题三'
    },
    {
        Choice: '.ql-header .ql-picker-item[data-value="4"]',
        title: '标题四'
    },
    {
        Choice: '.ql-header .ql-picker-item[data-value="5"]',
        title: '标题五'
    },
    {
        Choice: '.ql-header .ql-picker-item[data-value="6"]',
        title: '标题六'
    },
    {
        Choice: '.ql-header .ql-picker-item:last-child',
        title: '标准'
    },
    {
        Choice: '.ql-size .ql-picker-item[data-value="small"]',
        title: '小号'
    },
    {
        Choice: '.ql-size .ql-picker-item[data-value="large"]',
        title: '大号'
    },
    {
        Choice: '.ql-size .ql-picker-item[data-value="huge"]',
        title: '超大号'
    },
    {
        Choice: '.ql-size .ql-picker-item:nth-child(2)',
        title: '标准'
    },
    {
        Choice: '.ql-align .ql-picker-item:first-child',
        title: '居左对齐'
    },
    {
        Choice: '.ql-align .ql-picker-item[data-value="center"]',
        title: '居中对齐'
    },
    {
        Choice: '.ql-align .ql-picker-item[data-value="right"]',
        title: '居右对齐'
    },
    {
        Choice: '.ql-align .ql-picker-item[data-value="justify"]',
        title: '两端对齐'
    }
]
export const editorOption = {
    placeholder: "请在这里输入",
    modules: {
        toolbar: {
            container: [
                ['italic', 'bold', 'underline', 'strike'], //加粗，斜体，下划线，删除线
                ['blockquote', 'code-block'], //引用，代码块
                [{
                    'header': 1,
                    title: 'sdsds'
                }, {
                    'header': 2
                }], // 标题，键值对的形式；1、2表示字体大小
                [{
                    'list': 'ordered'
                }, {
                    'list': 'bullet'
                }], //列表
                [{
                    'script': 'sub'
                }, {
                    'script': 'super'
                }], // 上下标
                [{
                    'indent': '-1'
                }, {
                    'indent': '+1'
                }], // 缩进
                [{
                    'direction': 'rtl'
                }], // 文本方向
                [{
                    'size': ['small', false, 'large', 'huge']
                }], // 字体大小
                [{
                    'header': [1, 2, 3, 4, 5, 6, false]
                }], //几级标题
                [{
                    'color': []
                }, {
                    'background': []
                }], // 字体颜色，字体背景颜色
                [{
                    'align': []
                }],
                ['clean'], //清除字体样式
                //上传图片、上传视频
                ['link', 'image'],
            ],
            handlers: {
                'image': function (value) {
                    if (value) {
                        var contentImage = document.getElementsByClassName('contentImage')[0]
                        contentImage.click()
                    } else {
                        quill.format('image', false);
                    }
                }
            }
        },
        imageResize: {
            displayStyles: {
                backgroundColor: "black",
                border: "none",
                color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
        },
    }
}
import request from '@/utils/request'

export function getPageInit(shopId, marketPlaceId) {
    return request({
        url: '/api/caremail/pageInit',
        params: {
            shopId: shopId,
            marketPlaceId: marketPlaceId
        },
        method: 'get'
    });
}

export function getListPage(params) {
    return request({
        url: '/api/caremail/listPage',
        method: 'get',
        params
    });
}

export function addInit() {
    return request({
        url: '/api/caremail/addInit',
        method: 'get',
    });
}

export function getMailAccount(shopId, marketPlaceId) {
    return request({
        url: '/api/caremail/getMailAccount',
        method: 'get',
        params: {
            shopId: shopId,
            marketPlaceId: marketPlaceId
        }
    });
}

export function careMail(data) {
    return request({
        url: '/api/caremail',
        data,
        method: 'post'
    });
}
export function careMailUpdate(data) {
    return request({
        url: '/api/caremail',
        data,
        method: 'put'
    });
}

export function checkAsin(data) {
    return request({
        url: '/api/requestrules/checkAsin',
        data,
        method: 'post'
    });
}

export function chooseOrdersPage(params) {
    return request({
        url: '/api/requestrules/chooseOrdersPage',
        method: 'get',
        params
    });
}
export function batchUpdate(data) {
    return request({
        url: '/api/caremail/batchUpdate',
        data,
        method: 'put'
    });
}

export function updateInit(id) {
    return request({
        url: `/api/caremail/updateInit/${id}`,
        method: 'get'
    })
}

export function preview(data) {
    return request({
        url: '/api/caremail/getOrderList',
        data,
        method: 'post'
    });
}
export function tableSendView(params) {
    return request({
        url: `/api/caremail/getOrderListAtPage`,
        method: 'get',
        params
    });
}

export function getZoneTimeBycountryCode(params) {
    return request({
        url:'/api/caremail/getZoneTimeBycountryCode',
        method:'get',
        params
    })
}

export function sendTestMail(data) {
    return request({
        url:'/api/caremail/sendTestMail',
        method:'post',
        data
    });
}
