<template> 
  <!-- Feedback页面 -->
   <div>
     <el-tabs v-model="activeName" @tab-click="handleClick">
       <!-- 4-5星 -->
       <el-tab-pane label="4-5星" name="Favorable">
         <div class="append">
           <div class="seach">
            <el-select clearable placeholder="选择国家" v-model="pageInfo.countryId">
              <el-option
               v-for="item in countryList"
               :key="item.id"
               :label="item.name"
               :value="item.id"
              ></el-option>
            </el-select>
            <el-select clearable placeholder="选择店铺" v-model="pageInfo.profileId">
              <el-option-group 
                :key="group.id"
                :label="group.name"
                v-for="group in shopList"
              >
                <el-option 
                  v-for="item in group.marketPlaceTree"
                  :key="item.profileId"
                  :label="group.name+'-'+item.name"
                  :value="item.profileId"
                  @click.native="checkGroup(group.id, item.id)"
                 >{{ item.name }}
                </el-option>
              </el-option-group>
            </el-select>
            <el-select clearable multiple placeholder="全部星级" v-model="pageInfo.starLevel">
              <el-option
                v-for="item in FavorableStarList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select clearable placeholder="全部处理状态" v-model="pageInfo.processState">
              <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select clearable placeholder="评价时间" v-model="pageInfo.EvaluationTime">
              <el-option label="评价时间" value="0"></el-option>
              <el-option label="更新时间" value="1"></el-option>
            </el-select>
            <!-- 时间选择器 -->
            <TimeQuantum
              style="margin-right:10px" 
              @selectFinish='topTime'
              section="近7天"
              @sectionFinish='sectionSure'
            ></TimeQuantum>
            <el-input 
              clearable
              v-model="pageInfo.keyword"
              placeholder="请输入搜索内容" 
              style="width:200px; margin-right:10px"
            ></el-input>
            <el-button icon="el-icon-refresh" @click="favorableReset">重置</el-button>
            <el-button type="primary" icon="el-icon-search" @click="favorableSearch">查询</el-button>
           </div>
         </div>
         <div class="buttonGroup">
           <el-button type="primary" @click="MarkPendByFavorable">标记待处理</el-button>
           <el-button @click="MarkProcessByFavorable">标记处理中</el-button>
           <el-button @click="MarkDoneByFavorable">标记已完成</el-button>
         </div>
         <el-table
          border
          style="margin-top: 20px"
          :data="FavorableTableList"
          v-loading="pictLoading"
          element-loading-text = "数据正在加载中"
          element-loading-spinner = "el-icon-loading"
          @selection-change="handleSelectionChange"
          header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
         >
           <el-table-column type="selection" width="55" align="center"></el-table-column>
           <el-table-column label="店铺" prop="shopName" align="center"></el-table-column>
           <el-table-column label="国家" prop="marketplace" align="center"></el-table-column>
           <el-table-column label="买家信息" prop="name" align="center"></el-table-column>
           <el-table-column 
            prop="email" 
            align="center" 
            label="邮箱账号" 
            :show-overflow-tooltip="true"
           ></el-table-column>
           <el-table-column align="center" width="240">
             <template slot="header">
               <span>订单号</span>
               <el-tooltip effect="dark" placement="top" content="订单号仅供参考, 联系买家, 添加跟进记录时间">
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
             <template slot-scope="scope">
                <el-tooltip :content="scope.row.amazonOrderNumber" placement="top">
                   <span class='tipsInfo'>{{scope.row.amazonOrderNumber}}</span>
                </el-tooltip>
               <el-link type='primary' class="el-icon-edit exit" @click="editOrderNumber(scope.row.id,scope.row.tips)"></el-link>
             </template>
           </el-table-column>
           <el-table-column label="商品信息" align="center">
             <template slot-scope="scope">
               <el-popover placement="right" width="520" trigger="hover" transition="fade-in-linear" close-delay="30">
                 <!-- 鼠标悬浮 -->
                 <div>
                   <el-table 
                   :data="scope.row.itemList" 
                   :header-cell-style="{background:'#EFEFEF', textAlign:'center', height: '30px'}"
                   >
                    <el-table-column label="商品信息" prop="" align="center"></el-table-column>
                    <el-table-column label="品名/SKU" prop="msku" align="center"></el-table-column>
                    <el-table-column label="单价" prop="price" align="center"></el-table-column>
                    <el-table-column label="数量" prop="count" align="center"></el-table-column>
                   </el-table>
                 </div>
                 <!-- 展示图片 -->
                 <div class="goodsInfo" slot="reference">
                   <div class="noPic">
                     <span v-if="!scope.row.itemList[0]">暂无图片</span>
                     <img v-else :src="scope.row.itemList[0]" alt="" width="100%">
                   </div>
                 </div>
               </el-popover>
             </template>
           </el-table-column>
           <el-table-column label="feedback" align="center" :show-overflow-tooltip="true">
             <template slot-scope="scope">
               <el-rate disabled show-score v-model="scope.row.rateNumber"></el-rate>
               <span>{{scope.row.content}}</span>
             </template>
           </el-table-column>
           <el-table-column label="评论时间" prop="createTime" align="center" :show-overflow-tooltip="true"></el-table-column>
           <el-table-column label="更新时间" prop="updateTime	" align="center"></el-table-column>
           <el-table-column prop="operateTime" align="center">
              <template slot="header">
                <span>操作时间</span>
                <el-tooltip effect="dark" placement="top" content="操作标记状态, 联系买家, 添加根据时间">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
           </el-table-column>
           <el-table-column label="备注" prop="tips" align="center"></el-table-column>
           <el-table-column label="处理状态" prop="dealState" align="center">
             <template slot-scope="scope">
              <span v-if="scope.row.dealState=='未处理'" style="color: #FF0000">未处理</span>
              <span v-if="scope.row.dealState=='处理中'" style="color: #CD853F">处理中</span>
              <span v-if="scope.row.dealState=='已完成'" style="color: #008000">已完成</span>
             </template>
           </el-table-column>
           <el-table-column label="操作" align="center">
             <template slot-scope="scope">
               <el-link :underline="false" type="primary" @click="onClickbuyer">联系买家</el-link>
               <el-dropdown style="margin-left: 15px" type="primary" @command="handleCommand">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 120px">
                  <el-dropdown-item
                   v-for="item in operateTypeList"
                   :key="item.id"
                   :command="item.id"
                  >{{ item.value }}
                  </el-dropdown-item>
                </el-dropdown-menu>
               </el-dropdown>
             </template>
           </el-table-column>
         </el-table>
         <!-- 分页区域 -->
         <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           layout="total, sizes, prev, pager, next, jumper"
           :page-sizes="[20, 30, 50, 100]"
           :total="goodTotal"
           :page-size="pageInfo.pageSize"
           :current-page="pageInfo.current"
           style="text-align: center; margin-top: 15px"
          ></el-pagination>
       </el-tab-pane>
       <!-- 1-3星 -->
       <el-tab-pane label="1-3星" name="Badreview">
         <div class="append">
           <div class="seach">
           <el-select clearable placeholder="请选择国家" v-model="pageList.countryId">
             <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
             ></el-option>
           </el-select>
           <el-select clearable placeholder="选择店铺" v-model="pageList.profileId">
             <el-option-group 
               :key="group.id"
               :label="group.name"
               v-for="group in shopList"
             >
               <el-option 
                 v-for="item in group.marketPlaceTree"
                 :key="item.profileId"
                 :label="group.name+'-'+item.name"
                 :value="item.profileId"
                 @click.native="checkGroup(group.id, item.id)"
                >{{ item.name }}
               </el-option>
             </el-option-group>
           </el-select>
           <el-select clearable multiple placeholder="全部星级" v-model="pageList.starLevel">
            <el-option
              v-for="item in starLevelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
           </el-select>
           <el-select clearable placeholder="全部处理状态" v-model="pageList.processState">
             <el-option
               v-for="item in stateList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
             ></el-option>
           </el-select>
           <el-select clearable placeholder="评价时间" v-model="pageList.EvaluationTime">
             <el-option label="评价时间" value="0"></el-option>
             <el-option label="更新时间" value="1"></el-option>
           </el-select>
           <TimeQuantum
             style="margin-right:10px"
             @selectFinish='topTime2'
             section="近7天"
             @sectionFinish='sectionSure'
           ></TimeQuantum>
           <el-input 
            clearable 
            v-model="pageList.keyword" 
            placeholder="请输入搜索内容" 
            style="width:200px; margin-right:10px"
           ></el-input>
           <el-button icon="el-icon-refresh" @click="badReset">重置</el-button>
           <el-button type="primary" icon="el-icon-search" @click="badSearch">查询</el-button>
           </div>
         </div>
         <div class="buttonGroup">
           <el-button type="primary" @click="MarkPendByBad">标记待处理</el-button>
           <el-button @click="MarkProcessByBad">标记处理中</el-button>
           <el-button @click="MarkDoneByBad">标记已完成</el-button>
         </div>
         <el-table
          border
          :data="BadTableList"
          style="margin-top: 20px"
          v-loading="tableLoading"
          element-loading-text="数据正在加载中"
          element-loading-spinner="el-icon-loading"
          @selection-change="handleSelectionChange2"
          header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
         >
           <el-table-column type="selection" width="55" align="center"></el-table-column>
           <el-table-column label="店铺" prop="shopName" align="center"></el-table-column>
           <el-table-column label="国家" prop="marketplace" align="center"></el-table-column>
           <el-table-column prop="email" align="center" :show-overflow-tooltip="true">
             <template slot="header">
              <span>邮箱账号</span>
               <el-tooltip effect="dark" placement="top" content="订单号仅供参考, 支持手动编辑">
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column 
            align="center" 
            label="订单号" 
            prop="amazonOrderNumber"
            :show-overflow-tooltip="true"
           ></el-table-column>
           <el-table-column label="商品信息" prop="commoditySku" align="center">
             <template slot-scope="scope">
              <el-popover placement="right" width="620" trigger="hover" transition="fade-in-linear" close-delay="30">
                <!-- 鼠标悬浮 -->
                <div>
                  <el-table 
                  :data="scope.row.itemList" 
                  :header-cell-style="{background:'#EFEFEF', textAlign:'center', height: '30px'}"
                  >
                   <el-table-column label="商品信息" align="center" width="300px">
                     <template slot-scope="scope">
                      <div style="display:flex">
                        <div class="pic">
                          <span v-if="!scope.row.imageUrl">暂无图片</span>
                          <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                        </div>
                        <div class="suspend" style="width:320px; font-size:14px">
                           <p class="infoText">{{scope.row.title}}</p>
                           <span>
                             <p>asin:{{scope.row.asin}}</p>
                             <p><span>msku:{{scope.row.msku}}</span></p>
                           </span>
                        </div>
                      </div>
                     </template>
                   </el-table-column>
                   <el-table-column label="品名/SKU" prop="msku" align="center"></el-table-column>
                   <el-table-column label="单价" align="center">
                     <template slot-scope="scope">
                       <span>JP¥{{scope.row.price}}</span>
                     </template>
                   </el-table-column>
                   <el-table-column label="数量" prop="count" align="center"></el-table-column>
                  </el-table>
                </div>
                <div class="goodsInfo" slot="reference">
                  <div class="noPic">
                    <span v-if="scope.row.itemList.length == 0">暂无图片</span>
                    <p 
                     v-for="(item,index) in scope.row.itemList"
                     :key="index">
                     <img :src="item.imageUrl" alt="" width="100%">
                    </p>
                  </div>
                </div>
              </el-popover>
             </template>
           </el-table-column>
           <el-table-column align="center" :show-overflow-tooltip="true">
             <template slot="header">
               <span>feedback</span>
               <el-tooltip effect="dark" placement="top" content="已删除: 非亚马逊配送原因导致的, 内容原因已被删除或买家删除">
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
             <template slot-scope="scope">
               <el-rate disabled show-score v-model="scope.row.rateNumber"></el-rate>
               <span>{{scope.row.content}}</span>
             </template>
           </el-table-column>
           <el-table-column label="评论时间" prop="createTime" align="center"></el-table-column>
           <el-table-column label="更新时间" prop="updateTime" align="center"></el-table-column>
           <el-table-column prop="operateTime" align="center">
             <template slot="header">
              <span>操作时间</span>
               <el-tooltip effect="dark" placement="top" content="操作标记状态, 联系买家, 添加跟进记录的时间">
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column label="备注" align="center">
             <template slot-scope="scope">
                <el-tooltip :content="scope.row.tips" placement="top">
                  <span class='tipsInfo'>{{scope.row.tips}}</span>
                </el-tooltip>
                <el-link type='primary' class="el-icon-edit exit" @click="editNote(scope.row.id,scope.row.tips)"></el-link>
             </template>
           </el-table-column>
           <el-table-column label="处理状态" prop="dealState" align="center">
             <template slot-scope="scope">
              <span v-if="scope.row.dealState=='未处理'" style="color: #FF0000">未处理</span>
              <span v-if="scope.row.dealState=='处理中'" style="color: #CD853F">处理中</span>
              <span v-if="scope.row.dealState=='已完成'" style="color: #008000">已完成</span>
             </template>
           </el-table-column>
           <el-table-column label="操作" align="center">
             <template slot-scope="scope">
               <!-- <el-link :underline="false" type="primary">联系买家</el-link> -->
               <SendMail
                :shopId='shopId'
                :marketPlaceId='marketPlaceId'
               ></SendMail>

               <el-dropdown style="margin-left: 15px;margin-top:2px">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 120px">
                  <el-dropdown-item
                   v-for="item in operateTypeList"
                   :key="item.id"
                   :command="item.id"
                   @click.native="handleCommand2(scope.row, item)"
                  >{{ item.value }}
                  </el-dropdown-item>
                </el-dropdown-menu>
               </el-dropdown>
             </template>
           </el-table-column>
         </el-table>
         <!-- 分页区域 -->
         <el-pagination
           @size-change="handleSizeByPoor"
           @current-change="handleCurrentByPoor"
           layout="total, sizes, prev, pager, next, jumper"
           :page-sizes="[20, 30, 50, 100]"
           :total="badTotal"
           :page-size="pageList.pageSize"
           :current-page="pageList.current"
           style="text-align: center; margin-top: 15px"
           >
         </el-pagination>
       </el-tab-pane>
     </el-tabs>
     <!-- 跟进记录 -->
     <el-dialog
      width="40%"
      title="跟进记录" 
      :visible.sync="dialogTableVisible"
     >
      <el-row type="flex" justify="start">
        <el-button 
         type="primary" 
         icon="el-icon-circle-plus-outline"
         @click="handleAddRecord"
        >添加跟进记录</el-button>
        <el-select 
         clearable 
         filterable 
         v-model="followList.followUser"
         placeholder="全部跟进人" 
         style="width: 150px; margin: 0 20px"
        >
          <el-option
            v-for="item in followUserList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
        <TimeQuantum
          style="margin-right:10px"
          @selectFinish='topTime3'
          section="近7天"
          @sectionFinish='sectionSure'
        ></TimeQuantum>
        <el-button type="primary" icon="el-icon-search" @click="getFollowListPage(commandId)">搜索</el-button>
      </el-row>
      <el-table
        border 
        :data="followDataList"
        v-loading="followLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        style="margin-top: 20px"
        header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
      >
        <el-table-column label="跟进时间" prop="time" align="center"></el-table-column>
        <el-table-column label="跟进人" prop="user" align="center"></el-table-column>
        <el-table-column label="跟进内容" prop="content" align="center"></el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeByFollow"
        @current-change="handleCurrentByFollow"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[20, 30, 50, 100]"
        :total="followTotal"
        :page-size="followList.pageSize"
        :current-page="followList.current"
        style="text-align: center; margin-top: 15px"
        >
      </el-pagination>
      <span slot="footer">
        <el-button @click="dialogTableVisible=false">关 闭</el-button>
      </span>
     </el-dialog>
     <!-- 添加跟进记录 -->
     <el-dialog
      width="25%"
      title="添加跟进记录"
      :visible.sync="folloWRecordsVisible"
     >
       <el-form :model="followForm" ref="followForm" label-width="70px">
         <el-form-item label="跟进人">
           <el-input disabled v-model="this.$store.state.user.userName"></el-input>
         </el-form-item>
         <el-form-item label="跟进内容">
          <el-input 
            :rows="3" 
            type="textarea" 
            v-model="followForm.content"
            placeholder="请输入跟进内容" 
          ></el-input>
         </el-form-item>
       </el-form>
       <span slot="footer">
         <el-button @click="folloWRecordsVisible=false">取 消</el-button>
         <el-button 
          type="primary" 
          @click="handleConfirm"
          :disabled="!followForm.content?true:false" 
         >保 存</el-button>
       </span>
     </el-dialog>
     <!-- 操作 -->
     <el-dialog
      width="40%"
      title="操作日志"
      :visible.sync="operateLogVisible"
     >  
      <el-table 
       border 
       :data="operateTableList"
       header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
      >
        <el-table-column label="操作时间" prop="time" align="center"></el-table-column>
        <el-table-column label="操作人" prop="userName" align="center"></el-table-column>
        <el-table-column label="操作项" prop="type" align="center"></el-table-column>
        <el-table-column label="操作内容" prop="content" align="center"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeByLog"
        @current-change="handleCurrentByLog"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[20, 30, 50, 100]"
        :total="logTotal"
        :page-size="handleLogList.pageSize"
        :current-page="handleLogList.current"
        style="text-align: center; margin-top: 15px"
        >
      </el-pagination>
      <span slot="footer">
        <el-button @click="operateLogVisible=false">关 闭</el-button>
      </span>
     </el-dialog>
   </div>
</template>

<script>
import TimeQuantum from '@/components/TimeQuantum'
import SendMail from "@/components/sendMail.vue"
import {
  pageInit, 
  getlistPage,  // 查询分页
  modifyState,  // 修改状态
  followUserInit,  // 跟进人初始化数据
  followListPage,    // 跟进记录初始化
  addContent,   // 新增内容
  LogListPage,  // 操作日志
  editTips,     // 编辑备注
}from '@/api/Mail/Feedback.js';
import {
  pageInitList,
}from '@/api/Mail/customerList.js'
export default {
  components: {
    TimeQuantum,
    SendMail
  },
   data() {
     return {
      goodTotal: 0,
      badTotal: 0,
      logTotal: 0,
      followTotal: 0,
      pictLoading: false,
      tableLoading: false,
      followLoading: false,
      folloWRecordsVisible: false,
      activeName: "Favorable", 
      BadTableList: [],
      FavorableTableList: [],
      multipleSelection: [],
      multipleSelection2: [],
      shopList: [],
      stateList: [],
      countryList: [],
      shopId: "",
      marketPlaceId: "",
      dialogTableVisible: false,
      operateLogVisible: false,
      followUserList: [],
      followDataList: [],
      operateTableList: [],
      followUser: "",  // 跟进人
      commandId: "",
      pageInfo: {
        countryId: "",
        profileId: "", 
        starLevel: [],
        processState: "",  // 全部处理状态
        EvaluationTime: "",  // 评价时间
        startData: "",
        endData: "",
        keyword: "",
        current: 1,
        pageSize: 20,
      },
      pageList: {
        countryId: "",
        profileId: "",
        starLevel: [],
        processState: "",
        EvaluationTime: "",
        startData: "",
        endData: "",
        current: 1,
        pageSize: 20
      },
      FavorableStarList: [
        { id: 4, name: "四星"},
        { id: 5, name: "五星"},
      ],
      starLevelList: [
        { id: 1, name: "一星"},
        { id: 2, name: "二星"},
        { id: 3, name: "三星"},
      ],
      operateTypeList: [
        { id: 1, value: "标记待处理" },
        { id: 2, value: "标记处理中" },
        { id: 3, value: "标记已完成" },
        { id: 4, value: "跟进记录" },
        { id: 5, value: "操作日志" },
      ],
      followList: {
        followUser: "",
        current: 1,
        pageSize: 20,
        startDate: "",
        endDate: "",
        feedbackId: ""
      },
      followForm: {
        content: "", // 跟进内容
      },
      handleLogList: {
        current: 1,
        pageSize: 20,
      } 
     }
   },
   created() {
     this.getPageInit();
     this.getInitData();
   },
   mounted() {
     this.favorableSearch();
   },
   methods: {
     handleClick(tab) {
       if(tab.name == "Favorable") {
         this.favorableSearch();
       }else if(tab.name == "Badreview") {
         this.badSearch();
       }
     },
     // 好评重置
     favorableReset() {
       this.pageInfo.countryId = "";
       this.pageInfo.profileId = "";
       this.pageInfo.starLevel = "";
       this.pageInfo.processState = "";
       this.pageInfo.EvaluationTime = "";  // 评价时间
       this.pageInfo.keyword = "";
     },
     // 差评重置
     badReset() {
       this.pageList.countryId = "";
       this.pageList.profileId = "";
       this.pageList.starLevel = "";
       this.pageList.processState = "";
       this.pageList.EvaluationTime = "";
       this.pageList.keyword = "";
     },
     // 4-5星接口不对 
     favorableSearch() {
       this.pictLoading = true;
       let params = {
         country: this.pageInfo.countryId,
         keyword: this.pageInfo.keyword,
         // shopId: this.shopId,
         // marketPlaceId: this.marketPlaceId,
         dateType: this.pageInfo.EvaluationTime,
         // startDate: this.pageInfo.startData,
         // endDate: this.pageInfo.endData,
         dealState: this.pageInfo.processState,
         rating: this.pageInfo.starLevel.join(","),    // 评分值
         current: this.pageInfo.current,
         pageSize: this.pageInfo.pageSize
       }
       getlistPage(params)
       .then(res=> {
         this.pictLoading = false;
         this.FavorableTableList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
          // this.FavorableTableList = res.data.values;
          // this.goodTotal = res.data.pageInfo.total;
         }
       })
     },
     badSearch() {
       this.tableLoading = true;
       let params = {
         country: this.pageList.countryId,
         keyword: this.pageList.keyword,
        //  shopId: this.shopId,
        //  marketPlaceId: this.marketPlaceId,
         dateType: this.pageList.EvaluationTime,
        //  startDate: this.pageList.startData,
        //  endDate: this.pageList.endData,
         dealState: this.pageList.processState,
         rating: this.pageList.starLevel.join(","),
         current: this.pageList.current,
         pageSize: this.pageList.pageSize
       }
       getlistPage(params)
       .then(res=> {
         this.tableLoading = false;
         this.BadTableList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.BadTableList = res.data.values;
           this.badTotal = res.data.pageInfo.total;
         }
       })
     },
     checkGroup(parentId, subId) {
       this.shopId = parentId;
       this.marketPlaceId = subId;
     },
     topTime(val) {
       this.pageInfo.startData = val.value[0];
       this.pageInfo.endData = val.value[1];
     },
     topTime2(val) {
       this.pageList.startData = val.value[0];
       this.pageList.endData = val.value[1];
     },
     topTime3(val) {
       this.followList.startDate = val.value[0]+'23:59:59';
       this.followList.endDate = val.value[1]+'23:59:59';
     },
     // 获取店铺&&处理状态
     getPageInit() {
       pageInit()
        .then(res=> {
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.shopList = res.data.data.shopList;
            this.stateList = res.data.data.stateList;
            res.data.data.shopList.forEach(item=> {
              item.marketPlaceTree.forEach(data=> {
                if(data.isSelected == 1) {
                  this.shopId = item.id;
                  this.marketPlaceId = data.id;
                  this.pageInfo.profileId = data.profileId;  // 4-5星好评
                  this.pageList.profileId = data.profileId;  // 1-3星差评
                }
              })
            })
          }
        })
     },
     // 获取国家数据 
     getInitData() {
       pageInitList()
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.countryList = res.data.data.countryList;
         }
       })
     },
     // 4-5 标记待处理
     MarkPendByFavorable() {
       if(!this.multipleSelection.length) {
         this.$message.warning('请勾选需要操作的选项');
         return false;
       }
       this.handleStateChange(0);
     },
     // 标记处理中 
     MarkProcessByFavorable() {
       if(!this.multipleSelection.length) {
        this.$message.warning('请勾选需要操作的选项');
        return false;
       }
       this.handleStateChange(1);
     },
     // 标记已完成 
     MarkDoneByFavorable() {
       if(!this.multipleSelection.length) {
        this.$message.warning('请勾选需要操作的选项');
        return false;
       }
       this.handleStateChange(2);
     },
     handleStateChange(state) {
       let ids = [];
       this.multipleSelection.forEach(data=> {
         ids.push(data.id)
       })
       let data = {
         state: state,
         ids: ids
       }
       if(state == 0) {
         this.$confirm(`共${this.multipleSelection.length}个评论, 确定标记为待处理?`, '标记待处理', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
         }).then(() => {
           modifyState(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.favorableSearch();
             }
           })
         }).catch(() => {});
       }else if(state == 1) {
         this.$confirm(`共${this.multipleSelection.length}个评论, 确定标记为处理中?`, '标记处理中', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
         }).then(() => {
           modifyState(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.favorableSearch();
             }
           })
         }).catch(() => {});
       }else if(state == 2) {
         this.$confirm(`共${this.multipleSelection.length}个评论, 确定标记为已完成?`, '标记已完成', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
         }).then(() => {
           modifyState(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.favorableSearch();
             }
           })
         }).catch(() => {});
       }
     },
     // 1-3星 标记待处理 
     MarkPendByBad() {
       if(!this.multipleSelection2.length) {
         this.$message.warning('请勾选需要操作的选项');
         return false;
       }
       this.handleStateMethod(0);
     },
     // 标记处理中
     MarkProcessByBad() {
       if(!this.multipleSelection2.length) {
         this.$message.warning('请勾选需要操作的选项');
         return false;
       }
       this.handleStateMethod(1);
     },
     // 标记已完成
     MarkDoneByBad() {
       if(!this.multipleSelection2.length) {
         this.$message.warning('请勾选需要操作的选项');
         return false;
       }
       this.handleStateMethod(2);
     },
     handleStateMethod(state) {
       let ids = [];
       this.multipleSelection2.forEach(data=> {
         ids.push(data.id);
       })
       let data = {
         ids: ids,
         state: state,
         userId: this.$store.state.user.userId
       }
       if(state == 0) {
         this.$confirm(`共${this.multipleSelection2.length}个评论, 确定标记为待处理?`, '标记待处理', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
         }).then(() => {
           modifyState(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.badSearch();
             }
           })
         }).catch(() => {});
       }else if(state == 1) {
         this.$confirm(`共${this.multipleSelection2.length}个评论, 确定标记为处理中?`, '标记处理中', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
         }).then(() => {
           modifyState(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.badSearch();
             }
           })
         }).catch(() => {});
       }else if(state == 2) {
         this.$confirm(`共${this.multipleSelection2.length}个评论, 确定标记为已完成?`, '标记已完成', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
         }).then(() => {
           modifyState(data)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.badSearch();
             }
           })
         }).catch(() => {});
       }
     },
     handleCommand2(command, item) {
      this.commandId = command.id;
      if(item.id == 1) {  // 标记待处理
        this.editStateEvent(command.id, 0);
      }else if(item.id == 2) {  // 标记处理中
        this.editStateEvent(command.id, 1);
      }else if(item.id == 3) {  // 标记已完成
        this.editStateEvent(command.id, 2);
      }else if(item.id == 4) {   // 跟进记录
        this.getFollowUserList();
        setTimeout(()=> {
          this.getFollowListPage(command.id);
        }, 200)
        this.dialogTableVisible = true;
      }else if(item.id == 5) {  // 操作日志
        this.getLogListPage();
        this.operateLogVisible = true;
      }
     },
     // 修改状态方法
     editStateEvent(id, state) {
       let ids = [];
       ids.push(id);
       let data = {
         ids: ids,
         state: state,
         userId: this.$store.state.user.userId
       }
       modifyState(data)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.$message.success(res.data.message);
           this.badSearch();
         }
       })
     },
     getFollowUserList() {
       followUserInit()
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.followUserList = res.data.data;  
         }
       })
     },
     getFollowListPage(commandId) {
       this.followLoading = true;
       let params = {
         feedbackId: commandId,
         startDate: this.followList.startDate,
         endDate: this.followList.endDate,
         userId: this.followList.followUser,
         current: this.followList.current,
         pageSize: this.followList.pageSize,
       }
       followListPage(params)
       .then(res=> {
         this.followLoading = false;
         this.followDataList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.followDataList = res.data.values;
           this.followTotal = res.data.pageInfo.total;
         }
       })
     },
     handleSizeByFollow(newSize) {
       this.followList.pageSize = newSize;
       this.getFollowListPage(this.commandId);
     },
     handleCurrentByFollow(newCurrent) {
       this.followList.current = newCurrent;
       this.getFollowListPage(this.commandId);
     },
     handleSelectionChange(val) {
       this.multipleSelection = val;
     },
     handleSelectionChange2(val) {
       this.multipleSelection2 = val;
     },
     // 添加跟进记录 
     handleAddRecord() {
       this.followForm.content = "";
       this.folloWRecordsVisible = true;
     },
     handleConfirm() {
       let data = {
         content: this.followForm.content,
         createBy: this.$store.state.user.userId,
         feedbackId: this.commandId
       }
       addContent(data)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.folloWRecordsVisible = false;
           this.$message.success(res.data.message);
           this.followDataList.unshift({
             time: res.data.data.createTime,
             content: res.data.data.content,
             user: this.$store.state.user.userName
           })
         }
       })
     },
     getLogListPage() {
       let params = {
         feedbackId: this.commandId,
         current: this.handleLogList.current,
         pageSize: this.handleLogList.pageSize,
       }
       LogListPage(params)
       .then(res=> {
         this.operateTableList = [];
         if(res.data.code == 500) {
           this.message.error(res.data.message);
         }else {
           this.operateTableList = res.data.values;
           this.logTotal = res.data.pageInfo.total;
         }
       })
     },
     handleSizeByLog(newSize) {
       this.handleLogList.pageSize = newSize;
       this.getLogListPage();
     },
     handleCurrentByLog(newCurrent) {
       this.handleLogList.current = newCurrent;
       this.getLogListPage();
     },
     handleSizeByPoor(newSize) {
       this.pageList.pageSize = newSize;
       this.badSearch();
     },
     handleCurrentByPoor(newCurrent) {
       this.pageList.current = newCurrent;
       this.badSearch();
     },
     // 编辑订单号
     editOrderNumber(id, tips) {
       this.$prompt('请输入订单号', '', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         inputValue:tips,
         inputType:'textarea'
       }).then(({ value }) => {
         //  aaaa({id:id,tips:value}).then(res=>{
 
         //  })
       })
     },
     // 编辑备注  
     editNote(id, tips) {
       this.$prompt('请输入备注', '', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         inputValue:tips,
         inputType:'textarea'
       }).then(({ value }) => {
         editTips(value, this.$store.state.user.userId, id).then(res=>{ 
           if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }else {
             this.$message.success(res.data.message);
             this.badSearch();
           }
         })
       })
     }
   }
}
</script>

<style lang="scss" scoped>
.append {
  .seach {
    margin-top: 20px;
    .el-select {
      margin-right: 15px;
    }
  }
}
.buttonGroup {
  margin-top: 15px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.goodsInfo{
  display: flex;
  >div:nth-of-type(2){
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  p{
    font-size: 12px;
    color: #909399;
  }
}
.noPic{
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size:8px;
  color: #909399;
  margin-right: 15px;
  span{
    display: block;
    background: #EBEEF5;
    width: 100%;
    height: 100%;
  }
}
.tipsInfo{
  display: inline;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.infoText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
    margin: 15px 0 20px 0;
}
.suspend{
  p{
    span{
      display: inline-block;
      width: 140px;
      margin-right: 10px;
      font-size: 13px;
    }
  }
}
</style>